<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'

const props = withDefaults(
  defineProps<{
    currentPage?: number
    extremePagesLimit?: number // The number of first and last pages to be displayed.
    hasLoadMoreButton?: boolean
    hasNextButton?: boolean
    isLoadingMore?: boolean
    isOnWhiteBackground?: boolean
    lastPage?: number | null
    nearbyPagesLimit?: number // The number of pages that are displayed around the active page.
  }>(),
  {
    currentPage: 1,
    extremePagesLimit: 1,
    hasLoadMoreButton: false,
    hasNextButton: false,
    isLoadingMore: false,
    isOnWhiteBackground: true,
    lastPage: 1,
    nearbyPagesLimit: 1
  }
)

const emit = defineEmits<{
  (e: 'changePage', x: number): void
  (e: 'onLoadMoreButtonClick'): void
}>()

function range (start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (_, k: number): number => k + start)
}
</script>

<template>
  <div v-if="lastPage > 1">
    <BasicButton
      v-if="hasLoadMoreButton && currentPage < lastPage"
      class="mb-6"
      color="white"
      is-green-on-hover
      :is-loading="isLoadingMore"
      title="Показать ещё"
      @click="emit('onLoadMoreButtonClick')"
    />

    <BasicButton
      v-if="hasNextButton && currentPage < lastPage"
      class="mb-6"
      color="white"
      is-green-on-hover
      title="Дальше"
      @click="emit('changePage', currentPage + 1)"
    />

    <div
      class="pagination-area flex items-center justify-center"
      :class="{ 'on-white-background': isOnWhiteBackground }"
    >
      <a
        class="previous-button flex cursor-pointer items-center justify-center bg-white"
        :class="{ disabled: currentPage === 1 }"
        @click="emit('changePage', currentPage - 1)"
      >
        <SvgIcon name="outlined/navigate-before" />
      </a>

      <ul class="pagination flex flex-wrap items-center justify-center">
        <template v-if="currentPage > 1">
          <template v-for="i in range(1, extremePagesLimit)">
            <li v-if="i < currentPage - nearbyPagesLimit" :key="i">
              <a @click="emit('changePage', i)">{{ i }}</a>
            </li>
          </template>
          <li v-if="extremePagesLimit + 1 < currentPage - nearbyPagesLimit" class="disabled">
            …
          </li>
          <template v-for="i in range(currentPage - nearbyPagesLimit, currentPage - 1)">
            <li v-if="i > 0" :key="i">
              <a @click="emit('changePage', i)">{{ i }}</a>
            </li>
          </template>
        </template>

        <li class="active">
          <a @click="emit('changePage', currentPage)">{{ currentPage }}</a>
        </li>

        <template v-if="currentPage < lastPage">
          <template v-for="i in range(currentPage + 1, currentPage + nearbyPagesLimit)">
            <li v-if="i <= lastPage" :key="i">
              <a @click="emit('changePage', i)">{{ i }}</a>
            </li>
          </template>

          <li v-if="lastPage - extremePagesLimit > currentPage + nearbyPagesLimit" class="disabled">
            …
          </li>

          <template v-for="i in range(lastPage - extremePagesLimit + 1, lastPage)">
            <li v-if="i > currentPage + nearbyPagesLimit" :key="i">
              <a @click="emit('changePage', i)">{{ i }}</a>
            </li>
          </template>
        </template>
      </ul>

      <a
        class="next-button flex cursor-pointer items-center justify-center bg-white"
        :class="{ disabled: currentPage >= lastPage }"
        @click="emit('changePage', currentPage + 1)"
      >
        <SvgIcon name="outlined/navigate-next" />
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

.pagination-area {
  @media screen and (max-width: 1020px) {
    justify-content: space-between;
  }

  &.on-white-background {
    .next-button,
    .previous-button {
      background: variables.$body-background-color;

      &.disabled {
        color: white;
      }
    }
  }

  .next-button,
  .previous-button {
    border-radius: 50%;
    color: variables.$main-gray-color;
    font-size: 24px;
    height: 40px;
    min-width: 40px;
    width: 40px;

    &:hover {
      color: variables.$main-green-color;
    }

    &.disabled {
      color: variables.$main-light-gray-color;
      cursor: auto;
      pointer-events: none;
    }
  }
}

.pagination {
  list-style: none;
  margin: 0 98px;

  @media screen and (max-width: 1020px) {
    margin: 0 8px;
  }

  > li {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 8px;
    min-width: 28px;

    &.active,
    &:hover:not(.disabled) {
      > a {
        color: variables.$main-green-color;
        font-weight: 500;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    > a {
      box-sizing: border-box;
      color: variables.$main-gray-color;
      cursor: pointer;
      display: block;
      line-height: 20px;
      text-decoration: none;
    }
  }
}
</style>
